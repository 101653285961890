<template>
  <div class="equipmentDetail_wrap">
    <div class="equipmentDetail_box">
      <el-card class="box_card left_box_card">
        <div class="box_card_title_wrap">
          <div class="box_card_title">设备基本信息</div>
          <div class="right_btns">
            <el-button
              type="primary"
              size="small"
              plain
              color="#67C23A"
              @click="openEquipmentTopologyDialog"
              >设备拓扑图</el-button
            >
            <el-button
              class="Edit_btn"
              type="primary"
              size="small"
              plain
              @click="openEditEquipmentDialog"
              >编辑</el-button
            >
          </div>
        </div>
        <div class="equipmentDetail_left_section_body">
          <div class="left_img_box">
            <img :src="`${baseURL}${equipmentDetailData.pic}`" alt="设备图片" />
          </div>
          <div class="right_text_info_box">
            <div class="equipmentDetail_info_row">
              <span>设备名称：</span>{{ equipmentDetailData.name }}
            </div>
            <div class="equipmentDetail_info_row">
              <span>设备状态：</span>{{ equipmentDetailData.statusStr }}
            </div>
            <div class="equipmentDetail_info_row">
              <span>设备路径：</span>{{ equipmentDetailData.area }}
            </div>
            <div class="equipmentDetail_info_row">
              <span>接入时间：</span>{{ equipmentDetailData.createTime }}
            </div>
            <div class="area_text_info_row">
              <span>设备说明：</span>{{ equipmentDetailData.description }}
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="box_card right_box_card">
        <Weather :cityCode="equipmentDetailData.code" />
      </el-card>
    </div>
    <!-- 采集器列表 -->
    <div class="collectorList_box">
      <div class="collectorList_wrap">
        <div class="collectorList_head">
          <div class="collectorList_head_left">采集器列表</div>
          <div class="collectorList_head_right">
            <el-radio-group
              class="collectorList_status_select"
              @change="searchCollectorList"
              v-model="collectorListSearchData.statusStr"
            >
              <el-radio-button label="全部"></el-radio-button>
              <el-radio-button label="正常"></el-radio-button>
              <el-radio-button label="报警"></el-radio-button>
              <el-radio-button label="离线"></el-radio-button>
              <el-radio-button label="预警"></el-radio-button>
            </el-radio-group>
            <el-input
              class="collectorList_chipName_input"
              suffix-icon="el-icon-search"
              placeholder="请输入关键字"
              v-model="collectorListSearchData.chipName"
              @input="searchCollectorList"
              clearable
            >
            </el-input>
          </div>
        </div>
        <div class="collectorList_wrap_main">
          <el-table
            :data="collectorList"
            stripe
            :row-style="{ height: '75px' }"
            style="width: 100%"
            :show-header="false"
          >
            <el-table-column min-width="30%">
              <template #default="{ row }">
                <div class="scope">
                  <div class="chip_name">{{ row.chipName }}</div>
                  <div>ID：{{ row.code }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="25%">
              <template #default="{ row }">
                <div class="time">接入时间</div>
                <div class="time">{{ row.gmtCreate }}</div>
              </template>
            </el-table-column>
            <el-table-column min-width="10%">
              <template #default="{ row }">
                <div class="scope">
                  <div>会员套餐</div>
                  <div>{{ row.member ? '会员' : '非会员' }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="10%">
              <template #default="{ row }">
                <div class="scope">
                  <div>通知状态</div>
                  <div>{{ row.openNotify ? '开启' : '关闭' }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="10%">
              <template #default="{ row }">
                <div class="scope">
                  <div>状态</div>
                  <div>{{ row.statusStr }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" min-width="15%">
              <template #default="{ row }">
                <div class="btns">
                  <div class="btn" @click="handleGoCollectorDetailPage(row)">
                    查看
                  </div>
                  <div class="line"></div>
                  <div class="btn" @click="handleEidtCollector(row)">编辑</div>
                  <div class="line"></div>
                  <div class="btn" @click="deleteCollector(row)">删除</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination_row">
            <div class="pagination_row_left">共{{ totalCollectorNum }}条</div>
            <div class="pagination_row_right">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="totalCollectorNum"
                :page-size="PAGESIZE"
                v-model:current-page="collectorListSearchData.pageNum"
                @current-change="searchCollectorList"
                class="pagination_item"
              >
              </el-pagination>
              <div class="pagination_row_input_wrap">
                跳转到<el-input-number
                  class="pagination_row_input_box"
                  style="height: 30px"
                  v-model="pageInputNum"
                  :step="1"
                  :precision="0"
                  :min="1"
                  :max="maxPageNum"
                  size="small"
                />页
              </div>
              <el-button
                style="height: 30px"
                class="pagination_row_btn"
                size="small"
                @click="handleJumpPage"
                >确定</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 编辑设备弹窗窗口 -->
    <el-dialog
      v-model="editEquipmentDialogVisiable"
      title="编辑设备"
      width="50%"
      class="edit_equipment_dialog_box"
    >
      <!-- :before-close="handleClose" -->
      <div class="dialog_main">
        <el-form
          :model="editEquipmentData"
          label-width="80px"
          label-position="left"
        >
          <el-form-item label="设备名称" prop="name">
            <el-input
              v-model="editEquipmentData.name"
              size="default"
              style="width: 50%"
            ></el-input>
          </el-form-item>

          <el-form-item label="设备图片">
            <div class="equipment_img_wrap">
              <!-- v-show="oldEquipmentVisiable" -->
              <img
                class="old_equipment_img"
                :src="imgShowSrc"
                alt="设备图片"
                @click="uploadPicture(baseURL + editEquipmentData.pic)"
              />
              <!-- :src="`${baseURL}${editEquipmentData.pic}`" -->
              <el-button
                @click="uploadPicture(baseURL + editEquipmentData.pic)"
                type="primary"
              >
                更换图片
              </el-button>
              <!-- <div
                class="old_equipment_img_hover"
                @click="uploadPicture(baseURL + editEquipmentData.pic)"
              >
                更换
              </div> -->
              <!-- <el-upload
                class="upload-product-photo"
                v-model:file-list="editEquipmentData.imgFileList"
                method="post"
                ref="uploadRef"
                list-type="picture-card"
                :before-upload="handleBeforeUpload"
                :action="actionAddress"
                :headers="uploadHeaders"
                :auto-upload="true"
                :limit="1"
                :on-exceed="handleExceed"
                :on-success="handleSuccess"
                :on-error="handleError"
              >
                <el-icon><Plus /></el-icon>

                <template #file="{ file }">
                  <div>
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <el-icon><zoom-in /></el-icon>
                      </span>
                      <span
                        class="el-upload-list__item-delete"
                        @click="
                          handleRemove(file, editEquipmentData.imgFileList)
                        "
                      >
                        <el-icon><Delete /></el-icon>
                      </span>
                    </span>
                  </div>
                </template>
              </el-upload> -->
            </div>
          </el-form-item>
          <el-form-item label="设备说明" prop="description">
            <el-input
              class="description_input"
              v-model="editEquipmentData.description"
              placeholder="请输入设备说明"
              type="textarea"
              style="width: 50%"
            />
          </el-form-item>
          <el-form-item label="设备区域" prop="area">
            <el-cascader
              ref="cascaderRef"
              :options="regionDataRef"
              v-model="selectedOptions"
              style="width: 50%"
              size="default"
              @change="changeSelectedOptions"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="设备类型" prop="type">
            <el-select
              v-model="editEquipmentData.type"
              size="default"
              style="width: 50%"
            >
              <!-- 设备分类列表（弹窗部分去除全部) -->
              <el-option
                v-for="equipmentType in equipmentTypeList.filter(
                  (item) => item !== '全部'
                )"
                :key="equipmentType"
                :label="equipmentType"
                :value="equipmentType"
                size="default"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="设备功能" prop="func">
            <el-select
              v-model="editEquipmentData.func"
              size="default"
              style="width: 50%"
            >
              <el-option
                v-for="equipmentFunc in equipmentFuncList"
                :key="equipmentFunc"
                :label="equipmentFunc"
                :value="equipmentFunc"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog_footer">
          <!-- <el-button @click="handleClose">取消</el-button> -->
          <el-button @click="handleCancleEditEquipmentDialog" size="default"
            >取消</el-button
          >
          <el-button type="primary" @click="editEquipment" size="default"
            >提交</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 预览上传图片弹窗窗口 -->
    <!-- <el-dialog v-model="imgPreviewDialogVisible" center>
      <img
        class="preview_img"
        w-full
        :src="dialogImageUrl"
        alt="Preview Image"
      />
    </el-dialog> -->
    <!-- 编辑采集器弹窗窗口 -->
    <el-dialog
      v-model="isEditCollectorDialogVisiable"
      title="编辑采集器"
      width="50%"
      class="edit_collector_dialog_box"
    >
      <div class="dialog_main">
        <div class="dialog_main_title">编辑采集器</div>
        <el-form
          :model="editCollectorDetailData"
          label-width="180px"
          label-position="left"
        >
          <el-form-item label="采集器ID" prop="code">
            <div>{{ editCollectorDetailData.code }}</div>
          </el-form-item>
          <el-form-item label="采集器名称" prop="chipName">
            <el-input
              class="description_input"
              v-model="editCollectorDetailData.chipName"
              placeholder="请输入采集器名称"
              style="width: 50%"
            />
          </el-form-item>
          <!-- <el-form-item label="采集器状态">
            <div>{{ editCollectorDetailData.statusStr }}</div>
          </el-form-item>
          <el-form-item label="接入时间">
            <div>{{ editCollectorDetailData.gmtCreate }}</div>
          </el-form-item> -->
          <el-form-item label="是否开启通知">
            <el-switch v-model="editCollectorDetailData.openNotify" />
          </el-form-item>
          <el-form-item label="报警通知人名称列表">
            <div>{{ editCollectorDetailData.notifierNameStr }}</div>
          </el-form-item>
        </el-form>
        <div class="dialog_main_title">
          <div>告警通知人:</div>
          <el-button type="primary" @click="handleAddWaringNotifier"
            >添加</el-button
          >
        </div>
        <div
          class="waring_notifier_row"
          v-for="waringNotifier in editCollectorDetailData.waringNotifierList"
          :key="waringNotifier.id"
        >
          <div class="waring_notifier_left_wrap">
            <el-input
              class="waring_notifier_name_input"
              v-model="waringNotifier.name"
            ></el-input>
            <el-select
              class="waring_notifier_contactWay_select"
              v-model="waringNotifier.contactWay"
            >
              <el-option
                v-for="item in contactWayOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-input
              class="waring_notifier_phone_input"
              v-model="waringNotifier.phone"
            ></el-input>
          </div>

          <el-button
            type="danger"
            @click="handleDeleteWaringNotifier(waringNotifier.id)"
            >删除</el-button
          >
        </div>
      </div>
      <template #footer>
        <span class="dialog_footer">
          <!-- <el-button @click="handleClose">取消</el-button> -->
          <el-button @click="handleCancleEditCollector" size="default"
            >取消</el-button
          >
          <el-button type="primary" @click="handleEditCollector" size="default"
            >提交</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 剪裁组件弹窗 -->
    <el-dialog title="裁剪图片" v-model="cropperModel" width="800px" center>
      <CropperImage
        :Name="cropperName"
        @uploadImgSuccess="handleUploadSuccess"
        ref="child"
      ></CropperImage>
    </el-dialog>
    <!-- 设备拓扑图弹窗 -->
    <el-dialog
      v-model="isShowEquipmentTopology"
      title="设备拓扑图"
      width="1400px"
      class="topology_dialog"
      align-center
    >
      <Topology
        :equipmentName="equipmentDetailData.name"
        :equipmentId="equipmentDetailData.id"
        @goCollectorDetailPage="goCollectorDetailPage"
        @goCollectorUnitDetailPage="goCollectorUnitDetailPage"
      />
      <div class="left-top-mask">
        <!-- <div class="title">采集器状态：</div>
        <ul class="mask-ul">
          <li class="mask-li green">正常</li>
          <li class="mask-li red">异常</li>
          <li class="mask-li grey">离线</li>
        </ul>
        <div class="title">采集器单元状态：</div>
        <ul class="mask-ul">
          <li class="mask-li blue">正常</li>
          <li class="mask-li red">报警</li>
          <li class="mask-li yellow">预警</li>
          <li class="mask-li grey">离线</li>
        </ul> -->
        <div class="title">状态：</div>
        <ul class="mask-ul">
          <li class="mask-li blue">正常</li>
          <li class="mask-li red">报警</li>
          <li class="mask-li yellow">预警</li>
          <li class="mask-li grey">离线</li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import router from '@/router/index.js';
import {
  getEquipmentDetail,
  getEquipmentListType,
  putEditEquipment,
  getCollectorList,
  deleteCollectorById,
  getCollectorDetail,
  putCollector
} from '@/api/equipment.js';
// import { Delete, Plus, ZoomIn } from '@element-plus/icons-vue';
import { reactive, ref, watch, computed, onMounted } from 'vue';
import {
  ElMessage,
  ElMessageBox
  // genFileId
} from 'element-plus';
import { getItem, setItem } from '@/utils/storage';
import { regionData } from 'element-china-area-data'; // 区域代码数据
import { Weather, CropperImage, Topology } from './components';

// const baseURL = 'https://iottest.blueasiainfo.com:29202';
const baseURL = process.env.VUE_APP_BASE_API;

let initFlag = true;

// 监听
watch(
  () => router.currentRoute.value.fullPath,
  (newValue, oldValue) => {
    const id = getItem('equipmentId');
    if (newValue == '/equipment/equipmentDetail') {
      if (id != null) {
        router.push({ path: '/equipment/equipmentDetail', query: { id } });
        initFlag = false;
      } else {
        ElMessage.error('请从设备列表中选择设备');
        router.push({
          path: '/equipment/equipmentList'
        });
        initFlag = false;
      }
    }
  },
  { immediate: true }
);

// #region 编辑设备
const regionDataRef = ref(regionData);
console.log('regionData=>', regionData);

// 设备分类列表
const equipmentTypeList = ref();
// 获取设备分类列表信息Api
function getEquipmentTypeListApi() {
  getEquipmentListType().then((res) => {
    const { data } = res;
    equipmentTypeList.value = data;
  });
}

// 设备功能列表
const equipmentFuncList = ['实时监测', '延时监测'];

// 编辑设备是否显示
const editEquipmentDialogVisiable = ref(false);
// let editFLag = false; // 是否编辑过设备内容(简单判断一下,当输入内容改变的时候设置为true)
const editEquipmentData = reactive({
  area: '', // 设备区域
  areaCode: '', // 设备区域代码
  code: '', // 设备编号
  description: '', // 设备描述
  emergencyContactInfo: '', // 管理员信息
  func: '', // 设备功能 实时监测
  id: 0, // 设备id
  isIgnoreWarn: 0, // 是否忽略报警 0：否；1：是
  isTop: true, // 是否置顶
  name: '', // 设备名称
  nickname: '', // 用户昵称
  pic: '', // 设备图片
  status: 0, // 设备状态
  type: '', // 设备类型 电源监测
  userId: 0 // 用户id
  // imgFileList: [], // 上传照片用的数组
  // imgUrl: '' // 服务器返回的url值
});

// 关闭弹窗
// function handleClose(done) {
//   console.log('关闭弹窗');
//   if (!editFLag) {
//     console.log(done);
//     typeof done === 'function' && done();
//     editEquipmentDialogVisiable.value = false;
//     return;
//   }
//   ElMessageBox.confirm('您确定放弃更改设备关闭弹窗?', '提示', {
//     confirmButtonText: '确认',
//     cancelButtonText: '取消',
//     type: 'warning'
//   }).then(() => {
//     editEquipmentDialogVisiable.value = false;
//     editFLag = false;
//     typeof done === 'function' && done();
//   });
// }
// 点击取消按钮
function handleCancleEditEquipmentDialog() {
  editEquipmentDialogVisiable.value = false;
}

// 打开编辑设备弹窗
function openEditEquipmentDialog() {
  editEquipmentDialogVisiable.value = true;
}

// 获取编辑设备弹窗所需的信息
function initEditEquipmentDialog(data) {
  const {
    area,
    areaCode,
    code,
    description,
    emergencyContactInfo,
    func,
    id,
    isIgnoreWarn,
    isTop,
    name,
    nickname,
    pic,
    status,
    type,
    userId
  } = data;
  console.log('openEditEquipmentDialog', data);
  editEquipmentData.area = area;
  editEquipmentData.areaCode = areaCode;
  if (areaCode) {
    updateSelectOption(areaCode);
  } else {
    ElMessage.error('请联系管理员!报错:没有地区码!');
  }
  editEquipmentData.code = code;
  editEquipmentData.description = description;
  editEquipmentData.emergencyContactInfo = emergencyContactInfo;
  editEquipmentData.func = func;
  editEquipmentData.id = id;
  editEquipmentData.isIgnoreWarn = isIgnoreWarn;
  editEquipmentData.isTop = isTop;
  editEquipmentData.name = name;
  editEquipmentData.nickname = nickname;
  editEquipmentData.pic = pic;
  editEquipmentData.status = status;
  editEquipmentData.type = type;
  editEquipmentData.userId = userId;
}

// 编辑设备
function editEquipment() {
  const {
    code,
    description,
    emergencyContactInfo,
    func,
    id,
    isIgnoreWarn,
    isTop,
    name,
    nickname,
    pic,
    status,
    type,
    userId
    // imgUrl
  } = editEquipmentData;

  // 根据 areCode 得到对应的 省 市 区
  const rawAreaArr = [];
  const AreaArr = getAreaArr(selectedOptions);
  const newArea = AreaArr.join('');
  // 为areaCode 补 0
  rawAreaArr.push(selectedOptions[0] + '0000');
  rawAreaArr.push(selectedOptions[1] + '00');
  rawAreaArr.push(selectedOptions[2]);
  const newAreaCode = rawAreaArr.join(',');
  const params = {
    area: newArea,
    areaCode: newAreaCode,
    code,
    description,
    emergencyContactInfo,
    func,
    id,
    isIgnoreWarn,
    isTop,
    name,
    nickname,
    pic: imgFileName.value || pic,
    status,
    type,
    userId
  };
  console.log(params);
  // debugger;

  putEditEquipment(params).then((res) => {
    console.log(res);
    const { code, msg } = res;
    if (code < 300 && code >= 200) {
      ElMessage.success({
        message: '修改成功!'
      });
      editEquipmentDialogVisiable.value = false;
      initEquipmentDetail();
    } else {
      ElMessage.error({
        message: `修改失败!${msg}`
      });
    }
  });
}

// 修改选中的设备区域数组
function changeSelectedOptions(value) {
  console.log(value);
  for (let i = 0; i < 3; i++) {
    selectedOptions[i] = value[i];
  }
}

// 级联选择器Ref
const cascaderRef = ref(null);
// 选择设备区域数组 ["11","1101","110101"]
const selectedOptions = reactive(['11', '1101', '110101']);
// 更新选择设备区域数组
function updateSelectOption(areaCode) {
  const rawAreaArr = areaCode.split(',');
  rawAreaArr[0] = rawAreaArr[0].slice(0, 2);
  rawAreaArr[1] = rawAreaArr[1].slice(0, 4);
  editEquipmentData.areaCode = rawAreaArr.join(',');
  for (let i = 0; i < 3; i++) {
    selectedOptions[i] = rawAreaArr[i];
  }
  console.log(cascaderRef.value);
  // const areaArr = getAreaArr(rawAreaArr);
  // const areaStr = areaArr.join(' / ');
  // console.log('areaStr', areaStr);
  // console.log('cascaderRef.value.inputValue', cascaderRef.value.inputValue);
}

// 获取 Area 值 数组 省 市 区
function getAreaArr(rawAreaArr) {
  const areaArr = [];
  const firstLevelIndex = regionData.findIndex(
    (item) => item.value === rawAreaArr[0]
  );
  const firstLevelData = regionData[firstLevelIndex];
  areaArr.push(firstLevelData.label);
  const secodeLevelIndex = firstLevelData.children.findIndex(
    (item) => item.value === rawAreaArr[1]
  );
  const secondLevelData = firstLevelData.children[secodeLevelIndex];
  areaArr.push(secondLevelData.label);
  const thirdLevelIndex = secondLevelData.children.findIndex(
    (item) => item.value === rawAreaArr[2]
  );
  const thirdLevelData = secondLevelData.children[thirdLevelIndex];
  areaArr.push(thirdLevelData.label);
  console.log('areaArr', areaArr);
  if (areaArr[1] === '市辖区') {
    console.log('市辖区');
    areaArr[1] = areaArr[0];
  }
  return areaArr;
}

// 判断是否为图片类型
// function isImageType(str) {
//   // toLowerCase() 将字符串转换为小写，返回一个新的字符串
//   // bmp, gif, jpg, jpeg, png
//   return (
//     ['image/png', 'image/jpeg', 'image/gif', 'image/bmp'].indexOf(
//       str.toLowerCase()
//     ) !== -1
//   );
// }

// 上传地址
// const actionAddress = process.env.VUE_APP_BASE_API + '/api/common/upload';

// 图片弹窗信息
// const dialogImageUrl = ref(''); // 弹窗预览货物图片url
// const imgPreviewDialogVisible = ref(false); // 弹窗预览是否显示

// 上传模块
// const uploadRef = ref(null);
// 图片文件上传列表数据
// const uploadHeaders = {
//   'X-YAuth-Token': localStorage.getItem('token')
// };

// 处理上传前验证操作
// function handleBeforeUpload(rawFile) {
//   // TODO:这里可能要进行图片截取 成 宽900 : 高600格式的图片
//   // console.log(rawFile.type);
//   if (!isImageType(rawFile.type)) {
//     // 图片格式不对不给上传
//     ElMessage.error(
//       `图片不能为 ${rawFile.type}格式，可以为 png/jpg/jpeg/webp格式！`
//     );
//     return false;
//   } else if (rawFile.size / 1024 / 1024 > 10) {
//     // 大于 10MB
//     // 图片大小 大于 10MB 不给上传
//     ElMessage.error('图片不能大于 10MB！');
//     return false;
//   }
//   return true;
// }

// // 处理上传失败后执行
// function handleError() {
//   ElMessage({
//     type: 'error',
//     message: '上传图片失败，请重新上传试试！'
//   });
// }
// // 处理上传成功后执行
// // response, uploadFile, uploadFiles
// function handleSuccess(response, uploadFile, uploadFiles) {
//   if (response.code >= 200 && response.code < 300) {
//     ElMessage({
//       type: 'success',
//       message: '上传图片成功！'
//     });
//     console.log(response, uploadFile, uploadFiles);
//     // 更新当前的url值
//     uploadFile?.response?.data?.fileName &&
//       (editEquipmentData.imgUrl = uploadFile.response.data.fileName);
//   } else {
//     // 上传失败
//     ElMessage({
//       type: 'error',
//       message: '上传图片失败，请重新上传试试！'
//     });
//     // 回退操作
//     editEquipmentData.imgUrl = '';
//     const length = editEquipmentData.imgFileList?.length;
//     if (length) editEquipmentData.imgFileList.splice(0, length);
//     const thisRef = uploadRef.value;
//     thisRef.clearFiles();
//   }
// }

// // 超出规定范围后执行的方法
// function handleExceed(files) {
//   ElMessageBox.confirm(
//     '您已经上传了一张图片了，是否要覆盖上一张图片？',
//     '提示：',
//     {
//       confirmButtonText: '确定覆盖',
//       cancelButtonText: '取消',
//       type: 'warning'
//     }
//   ).then(() => {
//     const thisRef = uploadRef.value;
//     thisRef.clearFiles();
//     const file = files[0];
//     file.uid = genFileId();
//     thisRef.handleStart(file);
//     thisRef.submit();
//   });
// }

// // 点击移除按钮后触发的函数
// function handleRemove(uploadFile, uploadFiles) {
//   // console.log(uploadFile);
//   // upload.value?.handleRemove(uploadFile);
//   // console.log(uploadFiles);
//   uploadFiles.splice(0, 1);
// }

// // 点击放大镜预览按钮后触发的函数
// function handlePictureCardPreview(file) {
//   dialogImageUrl.value = file.url;
//   imgPreviewDialogVisible.value = true;
// }

// // 是否显示旧的设备图片
// const oldEquipmentVisiable = computed(() => {
//   return (
//     !editEquipmentData.imgFileList ||
//     editEquipmentData.imgFileList?.length === 0
//   );
// });
// #endregion

// 设备细节
const equipmentDetailData = reactive({
  id: 0,
  detailData: {},
  name: '',
  statusStr: '',
  area: '',
  pic: '',
  createTime: '',
  description: '',
  code: ''
  // areaCode: '' // 市级code
});

// 按照id查询设备详情 获取对应的采集器列表
function initEquipmentDetail() {
  if (equipmentDetailData.id) {
    getEquipmentDetail(equipmentDetailData.id).then((res) => {
      const { data, code, msg } = res;
      if (code === 200) {
        console.log('initEquipmentDetail ', data);
        localStorage.setItem('equipmentType', data?.type);
        // 这里临时加个判断
        if (data.areaCode) {
          equipmentDetailData.code = data.areaCode.split(',')[2];
          // equipmentDetailData.areaCode = data.areaCode.split(',')[1];
          setItem('cityCode', equipmentDetailData.code);
          // 获取天气信息
          // getWeather(equipmentDetailData.areaCode, equipmentDetailData.code);
        } else {
          ElMessage.error('天气预报异常!请联系管理员!');
        }

        for (const key of Object.keys(data)) {
          equipmentDetailData.detailData[key] = data[key];
        }
        equipmentDetailData.name = data.name;
        equipmentDetailData.statusStr = data.statusStr;
        equipmentDetailData.area =
          data.area + '/' + data.type + '/' + data.func;
        equipmentDetailData.pic = data.pic;
        equipmentDetailData.createTime = data.createTime;
        equipmentDetailData.description = data.description;

        // 获取设备信息
        initEditEquipmentDialog(data);

        // 设置采集器列表信息
        searchCollectorList();
      } else {
        ElMessage.error(msg);
      }
    });
  }
}

// #region 分页逻辑部分
const totalCollectorNum = ref(1); // 总数据条数
const PAGESIZE = 10; // 一页展示的数据条数

const pageInputNum = ref(1); // 输入框页码
const maxPageNum = ref(1); // 输入框中最大页码

// 页码跳转
function handleJumpPage() {
  collectorListSearchData.pageNum = pageInputNum.value;
  searchCollectorList();
}
// #endregion

// 采集器列表(用于el-table展示)
const collectorList = reactive([
  // {
  //   chipId: 73, // 采集器id
  //   equipmentId: 77, // 设备id
  //   gmtCreate: '2022-12-05 23:40:21',
  //   code: '5D8FF373338564643175155', // 采集器串号
  //   chipStatus: 4, // 状态，1:正常， 2:报警，3:离线, 4:预警
  //   metrics: [], // 采集单元指标信息
  //   chipName: '电源监测采集器', // 采集器名称
  //   equipmentAndChipName: '', // 隶属设备/采集器
  //   notifierNameStr: '', // 报警通知人名称列表
  //   waringNotifierList: [], // 报警通知人
  //   waringMsgList: [], // 报警信息
  //   openNotify: false, // 是否开启通知
  //   member: true, // 是否拥有会员套餐
  //   statusStr: '离线' // 状态字符串
  // }
]);
// 搜索要用到的数据
const collectorListSearchData = reactive({
  chipName: '',
  statusStr: '全部',
  pageNum: 1 // 当前页码
});

// 按照条件搜索对应的采集器
function searchCollectorListApi(params) {
  getCollectorList(params).then((res) => {
    const { code, data, msg } = res;
    if (code === 200) {
      const { list, total, pages } = data;
      const length = collectorList.length;
      collectorList.splice(0, length, ...list);
      totalCollectorNum.value = total;
      maxPageNum.value = pages;
    } else {
      ElMessage.error(msg);
    }
  });
}

// 按照条件搜索对应的采集器
function searchCollectorList() {
  // 按照条件搜索对应的采集器
  const { chipName, statusStr, pageNum } = collectorListSearchData;
  const params = {
    chipName,
    pageNum,
    pageSize: PAGESIZE,
    equipmentId: equipmentDetailData.id
  };
  let chipStatus;
  switch (statusStr) {
    case '正常':
      chipStatus = 1;
      break;
    case '报警':
      chipStatus = 2;
      break;
    case '离线':
      chipStatus = 3;
      break;
    case '预警':
      chipStatus = 4;
      break;
  }
  if (chipStatus) {
    params.chipStatus = chipStatus;
  }
  console.log('searchCollectorList params=>', params);
  searchCollectorListApi(params);
}

// 需要判断，是否付费有效期
function ifPayTime(member) {
  let flag = true;
  if (!member) {
    flag = false;
    const userInfoId = getItem('userInfo').id;
    if (editEquipmentData.userId != userInfoId) {
      ElMessageBox.confirm(
        '尊敬的用户您好，当前设备的套餐服务已过期或未购买，请及时通知设备拥有者订购服务，以保障设备服务正常运行！',
        '温馨提示',
        {
          cancelButtonText: '我知道了',
          showConfirmButton: false,
          type: 'warning'
        }
      );
    } else {
      ElMessageBox.confirm(
        '尊敬的用户您好，当前设备的套餐服务已过期或未购买，请及时通知设备拥有者订购服务，以保障设备服务正常运行！',
        '温馨提示',
        {
          cancelButtonText: '我知道了',
          showConfirmButton: false,
          type: 'warning'
        }
      );
    }
  }
  return flag;
}

// 处理采集器列表中 点击查看
function handleGoCollectorDetailPage(dataRow) {
  console.log('dataRow=>', dataRow);
  const { chipId, member } = dataRow;
  // 判断有没有过期,没过期就跳转到详情页
  ifPayTime(member) && goCollectorDetailPage(chipId);
}

// 跳转到采集器详情页
function goCollectorDetailPage(chipId) {
  setItem('chipId', chipId);
  router.push({
    path: '/equipment/collectorDetail',
    query: {
      id: chipId,
      cityCode: equipmentDetailData.code
    }
  });
}

// 跳转到采集器单元详情页
function goCollectorUnitDetailPage(chipId, UnitId) {
  setItem('chipId', chipId);
  setItem('UnitId', UnitId);
  router.push({
    path: '/equipment/collectorUnitDetail',
    query: { id: UnitId }
  });
}

// 编辑采集器
function handleEidtCollector(dataRow) {
  const { chipId, member } = dataRow;
  if (!ifPayTime(member)) {
    return;
  }
  isEditCollectorDialogVisiable.value = true;
  editCollectorDetailData.chipId = chipId;
  // TODO:打开编辑弹窗
  getCollectorDetail(chipId).then((res) => {
    const { code, data, msg } = res;
    if (code === 200) {
      for (const key of Object.keys(editCollectorDetailData)) {
        editCollectorDetailData[key] = data[key];
      }
      // 初始化当前id
      currentWaringNotifierId = 0;
      // 给 waringNotifierList 配id
      editCollectorDetailData.waringNotifierList.forEach((item) => {
        item.id = currentWaringNotifierId++;
      });

      // console.log('eidtCollector data', data);
      // console.log(
      //   'eidtCollector editCollectorDetailData',
      //   editCollectorDetailData
      // );
    } else {
      ElMessage.error(msg);
    }
  });
}

// 删除采集器
function deleteCollector(rowData) {
  const { chipId, chipName } = rowData;
  console.log('deleteCollector', chipId);
  ElMessageBox.confirm(`是否要删除 ${chipName}?`, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      deleteCollectorById(chipId).then((res) => {
        const { data, code } = res;
        console.log('删除成功' + data);
        if (code === 200) {
          ElMessage({
            type: 'success',
            message: '删除成功!'
          });
        }
        // TODO:
        // 删除的是最后一个设备,并且现在不是第一页
        // if (
        //   EquipmentList.value?.length === 1 &&
        //   equipmentSearchData.pageNum !== 1
        // ) {
        //   equipmentSearchData.pageNum -= 1;
        // }
        // getEquipmentList();
        // resolve(response);
      });
    })
    .catch(() => {}); // 添加错误捕获;;
}

// 编辑采集器
const isEditCollectorDialogVisiable = ref(false);
let currentWaringNotifierId = 0;
const editCollectorDetailData = reactive({
  chipId: 0, // 当前编辑的采集器chipId
  chipName: '', // 采集器名称
  chipStatus: 0, // 状态，1:正常， 2:报警，3:离线, 4:预警
  code: '', // 采集器串号
  equipmentAndChipName: '', // 隶属设备/采集器
  equipmentId: 0, // 设备id
  gmtCreate: '', // 接入时间
  member: true, // 是否拥有会员套餐
  notifierNameStr: 'string', // 报警通知人名称列表
  openNotify: true, // 是否开启通知
  statusStr: 'string', // 采集器状态字符串
  waringNotifierList: [
    // 报警通知人列表
    // {
    //   contactWay: 0, // 通知方式 电话方式通知:1, 短信方式通知:2, 电话+短信通知: 3.电话
    //   name: '', // 报警通知人
    //   phone: '' // 手机号
    // }
  ]
});

const contactWayOptions = [
  { value: 1, label: '短信通知' },
  { value: 2, label: '电话+短信通知' },
  { value: 3, label: '电话' }
];

// 添加通知人
function handleAddWaringNotifier() {
  editCollectorDetailData.waringNotifierList.push({
    contactWay: 1,
    name: '',
    phone: '',
    id: currentWaringNotifierId++
  });
}

// 删除通知人
function handleDeleteWaringNotifier(waringNotifier) {
  const index = editCollectorDetailData.waringNotifierList.findIndex(
    (item) => item.id === waringNotifier.id
  );
  editCollectorDetailData.waringNotifierList.splice(index, 1);
}

// 取消编辑采集器
function handleCancleEditCollector() {
  isEditCollectorDialogVisiable.value = false;
}

// 编辑采集器
function handleEditCollector() {
  const {
    chipId,
    chipName,
    // chipStatus,
    code,
    equipmentId,
    openNotify,
    waringNotifierList
  } = editCollectorDetailData;
  const params = {
    chipId,
    chipName,
    // chipStatus,
    code,
    equipmentId,
    openNotify,
    waringNotifierList
  };
  // TODO: 对输入数据的校验
  // waringNotifierList
  console.log('handleEditCollector=>', params);
  putCollector(params).then((res) => {
    console.log(res);
    const { code, msg } = res;
    if (code === 200) {
      console.log(res);
      ElMessage.success('修改成功!');
      isEditCollectorDialogVisiable.value = false;
      searchCollectorList();
    } else {
      ElMessage.error(msg);
      console.log('修改失败!', res);
    }
  });
}

function init() {
  equipmentDetailData.id = router.currentRoute.value.query.id;
  if (equipmentDetailData.id) {
    getEquipmentTypeListApi();
    initEquipmentDetail();
  }
}
onMounted(() => {
  initFlag && init();
});

// <!-- :src="`${baseURL}${editEquipmentData.pic}`" -->
const imgShowSrc = computed(() => {
  return (
    baseURL + (imgFileName.value ? imgFileName.value : editEquipmentData.pic)
  );
});

// 是否显示 裁剪图片 弹框
const cropperModel = ref(false);
// 默认图片的路径
const cropperName = ref(''); // 默认图片参数

// 成功上传后的回调地址 fileName
const imgFileName = ref('');

// 在此处调文件上传的接口
function handleUploadSuccess(data) {
  console.log('handleUploadSuccess data', data);

  // 保持它的fileName
  imgFileName.value = data.fileName;
  cropperModel.value = false;
}

// 上传图片
function uploadPicture(name) {
  cropperModel.value = true;
  cropperName.value = name;
}

// 是否显示拓扑图弹框
const isShowEquipmentTopology = ref(false);

// 打开拓扑图弹框
function openEquipmentTopologyDialog() {
  isShowEquipmentTopology.value = true;
}
</script>

<style lang="scss" scoped>
.equipmentDetail_wrap {
  background-color: #fafafa;
  .equipmentDetail_box {
    background-color: #fff;
    margin: 1rem 14px 8px 14px;
    display: flex;
    justify-content: space-between;

    .box_card {
      width: 49.5%;
      min-width: 710px;

      // 设备详情
      &.left_box_card {
        .box_card_title_wrap {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          .box_card_title {
            font-size: 18px;
          }
          .right_btns {
            &:first-child {
              margin-right: 10px;
            }
          }
        }
        .equipmentDetail_left_section_body {
          display: flex;
          justify-content: space-between;
          .left_img_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45%;
            img {
              width: 100%;
              border-radius: 10px;
            }
          }
          .right_text_info_box {
            width: 50%;
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            .equipmentDetail_info_row {
              // 省略三连
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              font-size: 14px;
              width: 100%;
              height: 30px;
              line-height: 30px;
              // color: rgba(0, 0, 0, 0.65);
              color: black;
              padding-left: 5%;
              span {
                color: #9a9a9a;
              }
            }
            .area_text_info_row {
              font-size: 14px;
              width: 100%;
              line-height: 30px;
              // color: rgba(0, 0, 0, 0.65);
              color: black;
              padding-left: 5%;
              span {
                color: #9a9a9a;
              }
            }
          }
        }
      }
    }
  }
  .collectorList_box {
    margin: 14px;
    background-color: #fff;

    .collectorList_wrap {
      padding: 14px;

      .collectorList_head {
        height: 40px;
        line-height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .collectorList_head_left {
          font-size: 18px;
        }

        .collectorList_head_right {
          display: flex;
          height: 40px;
          align-items: center;

          .collectorList_status_select {
            flex: 2;
            margin-right: 15px;
          }

          .collectorList_chipName_input {
            flex: 1.2;
            height: 32px;
            :deep(.el-input__inner) {
              height: 32px;
            }
          }
        }
      }

      .collectorList_wrap_main {
        margin-top: 10px;
        :deep(.scope) {
          display: flex;
          flex-direction: column;
          .chip_name {
            color: rgba(0, 0, 0, 0.95);
            font-size: 14px;
          }
        }

        :deep(.btns) {
          display: flex;

          .btn {
            margin: 0 8px;
            color: #1890ff;
            cursor: pointer;
          }

          .line {
            margin-top: 2px;
            width: 1px;
            height: 16px;
            border-left: 1px solid #dcdfe6;
          }
        }

        .pagination_row {
          margin-top: 10px;
          height: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .pagination_row_left {
            font-size: 13px;
            color: #606266;
          }

          .pagination_row_right {
            display: flex;
            font-size: 13px;
            align-items: center;

            .pagination_item {
              height: 30px;
              :deep(button) {
                height: 30px;
              }
              :deep(ul li) {
                height: 30px;
              }
            }
            .pagination_row_input_wrap {
              margin-left: 10px;
              .pagination_row_input_box {
                margin-left: 10px;
                margin-right: 10px;
              }
              // 这部分是可以自然设置number input部分样式(保留)
              // #region
              // .pageInput {
              //   &:deep(.el-input__inner) {
              //     height: 30px;
              //     line-height: 30px;
              //     box-sizing: border-box;
              //   }
              //   &:deep(.el-input-number__decrease) {
              //     height: 30px;
              //     line-height: 30px;
              //     box-sizing: border-box;
              //   }
              //   &:deep(.el-input-number__increase) {
              //     height: 30px;
              //     line-height: 30px;
              //     box-sizing: border-box;
              //   }
              // }
              // #endregion
            }

            .pagination_row_btn {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  // 编辑设备
  .edit_equipment_dialog_box {
    .dialog_main {
      .equipment_img_wrap {
        display: flex;
        align-items: center;
        .old_equipment_img {
          height: 148px;
          margin-right: 8px;
          box-sizing: border-box;
          border: 1px solid var(--el-border-color);
          border-radius: 6px;
        }
        .upload-product-photo {
          :deep(.el-upload-list__item) {
            height: 148px;
            width: 221px;
          }
        }
      }
    }
  }

  // 编辑采集器
  .edit_collector_dialog_box {
    .dialog_main {
      .dialog_main_title {
        display: flex;
        text-align: left;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      .waring_notifier_row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .waring_notifier_name_input {
          width: 150px;
          :deep(.el-input__wrapper) {
            width: 150px;
          }
          margin-right: 15px;
        }
        .waring_notifier_contactWay_select {
          :deep(.el-input) {
            width: 150px;
          }
          margin-right: 15px;
        }
        .waring_notifier_phone_input {
          width: 200px;
          :deep(.el-input__wrapper) {
            width: 200px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.equipmentDetail_wrap {
  .el-dialog {
    .el-dialog__header {
      height: 50px;
      .el-dialog__headerbtn {
        .el-icon.el-dialog__close {
          font-size: 16px;
        }
      }
    }
    .el-dialog__body {
      text-align: center;
      .preview_img {
        width: 600px;
      }
    }
  }

  // 拓扑图弹框
  .topology_dialog {
    .el-dialog__body {
      padding: 0;
      position: relative;
    }
    .left-top-mask {
      // background-color: pink;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      text-align: left;
      width: 80px;
      .title {
        font-size: 14px;
        margin-bottom: 8px;
      }
      .mask-ul {
        list-style: disc;
        list-style-position: inside;
        margin-bottom: 16px;

        .mask-li {
          height: 20px;
          line-height: 20px;
        }

        .red {
          color: #d84242;
        }
        .green {
          color: #3dce6d;
        }
        .blue {
          color: #4e81ec;
        }
        .yellow {
          color: #f59a23;
        }
        .grey {
          color: grey;
        }
      }
    }
  }
}
</style>
